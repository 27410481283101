.password-input-group {
    position: relative;
  }
  
  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1; /* Ensure the icon stays above the input */
  }
  