.header1 {
  display: flex;
  flex-wrap: wrap;
  gap: 0.1rem;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ccc;
  position: relative;
  z-index: 1; /* Higher z-index */
}

.filter-btn,
.save-search-btn {
  margin: 5px;
  padding: 0px 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  /* top: 59px !important; */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
}

.filter-btn:hover,
.save-search-btn:hover {
  background-color: #eee;
}

.save-search-btn {
  background-color: #007bff;
  color: white;
}

.save-search-btn:hover {
  background-color: #0056b3;
}
.listgrid {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
.listgrid1 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}
.btn-filter2{
  display: none;
}
@media (max-width: 768px) {
  .newhello {
    margin-left: 5px !important;
  }
  .top-res {
    margin-top: 100px !important;
  }
  .btn-filter1 {
    display: none;
    /* width: 100px !important; */
    padding: 10px 50px;
    /* overflow: scroll; */
    margin-left: 4px !important;
  }
  .btn-filter2 {
    display: block;
    /* width: 100px !important; */
    padding: 0px 50px;
    /* overflow: scroll; */
    margin-left: 4px !important;
  }
  .header {
    flex-direction: column;
    background-color: white;
    height: 100%;
    align-items: flex-start;
    margin-top: 39px;
  }

  .dropdown {
    width: 100%;
  }
  .filter-input {
    width: 100%;
  }
  .fa-server {
    margin-top: 5px;
  }
  .filter-count {
    position: relative;
    top: -10px;
    color: blue !important;
  }
  .toggle-btn {
    /* display: block; */
    display: flex;
    margin-bottom: 10px;
    /* width: 200px !important; */
    /* background-color: #007bff; */
    color: black;
    border: 1px solid gray !important;
    padding: 8px 50px;
    margin-right: 10px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  .toggle-btnfn {
    /* display: block; */
    display: flex;
    margin-bottom: 10px;
    /* width: 200px !important; */
    /* background-color: #007bff; */
    color: black;
    border: 1px solid gray !important;
    /* padding: 8px 50px; */
    width: 100px;
    margin-right: 10px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }
  .toggle-btnf {
    /* display: block; */
    display: flex;
    margin-bottom: 10px;
    /* width: 200px !important; */
    /* background-color: #007bff; */
    color: black;
    border: none;
    /* border: 1px solid gray !important; */
    padding: 8px 50px;
    margin-right: 10px;
    background-color: transparent;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
  }

  .dropdowns {
    display: none;
    width: 100%;
    flex-direction: column;
    position: relative;
    top: 50%;
    /* left: -22%; */
  }

  .dropdowns.show {
    display: flex;
  }
}
@media (min-width: 100px) {
  .newhello {
    margin-left: 140px;
  }
  /* .filter-btn{} */
  .btn-filter1 {
    margin-top: 5px;
    margin-left: 11px;
  }
  .dropdowns {
    display: flex !important;
    flex-wrap: wrap !important;
  }
}
.filter-input {
  margin: 5px;
  padding: 8px 15px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.top-res {
  margin-top: 20px;
}
@media (min-width: 600px) {
  .toggle-btnf,
  .toggle-btn,
  .toggle-btnfn {
    display: none;
  }
}
