.App-one {
  text-align: center;
  color: white;
  background-color: #000;
  padding: 20px;
  height: 100%;
}

.header-one {
  margin-bottom: 20px;
}

.countries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.country {
  border: 1px solid #fff;
  /* padding: 10px; */
  border-radius: 5px;
  width: 360px;
  height: 326px;
  text-align: center;
  background-color: #ffffff;
  transition: transform 0.3s;
}

.country:hover {
  transform: scale(1.05);
}

.country img {
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 239px;
}

/* .footer {
  margin-top: 200px;
} */
.h2-one {
  font-size: 24px;
  color: #fff;
}
.p {
  color: #ffffff;
  font-size: 14px;
  margin-top: 100px;
}
hr {
  color: white;
}
/* .img-coun{
    width: 100%;
    height:  239px;
} */
.country-name {
margin-top: 20px;
color: black;
font-size: 18px;
font-weight: 500;
}
